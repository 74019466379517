.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.close-icon {
  font-size: 1.5rem;
  line-height: 1;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  color: azure;
}



@media (max-width: 544px) {  
  .collectiontype {font-size:1.5rem;} /*1rem = 16px*/
}

@media (min-width: 544px) {  
  .collectiontype {font-size:3.5rem;} /*1rem = 16px*/
}

@media (max-width: 544px) {  
  .figheight {height: 250px;} /*1rem = 16px*/
}

@media (min-width: 544px) {  
  .figheight {height: 400px;} /*1rem = 16px*/
}




.site-blocks-cover1 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .site-blocks-cover1, .site-blocks-cover1 .row {
    min-height: 100px;
    height: calc(60vh - 174px); }
  .site-blocks-cover1 h1 {
    font-size: 30px;
    font-weight: 900;
    color: #000000; 
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}
    @media (min-width: 992px) {
      .site-blocks-cover1 h1 {
        font-size: 50px; } }
  .site-blocks-cover1 p {
    color: #282828;
    font-size: 20px;
    line-height: 35px; }
  .site-blocks-cover1 .intro-text {
    font-size: 16px;
    line-height: 1.5; }





    /* home */


    .carousel-indicators li {

      background-color: rgb(0 0 0 / 50%);
    }

    .carousel-indicators .active {

      background-color: #000000;

    }


    .logobgcolor {
      background-color: rgb(231, 203, 222) ;
      background-image: linear-gradient(to right, rgb(248, 228, 237), rgb(255, 230, 230));
    }


    .navbgcolor {
      background-color: rgb(175 86 148) ;
      background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    }
